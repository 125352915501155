<mat-dialog-content class="paddingZero">
    <div class="modalHeader">
        <div class="image">
            <img src="assets/warning-icon.svg" alt="" class="icon" />
        </div>
        <div class="titleContainer">
            <div class="mainTitle">An Error Has Occurred</div>
        </div>
    </div>
    <div class="contentContainer">
        <div>
            <h3>Error Message</h3>
            <p>
                {{errorMessage}}
            </p>
        </div>
        <div>
            <h3> EventId</h3>
            <p>
                {{eventId ?? "[Event Id not available]"}}
            </p>
        </div>
        <div>
            <h3> Stacktrace</h3>
            <p class="stacktrace"> {{stacktrace ?? "[Stacktrace not available]"}}</p>
        </div>
    </div>

    <div class="actionContainer">
        <button mat-button mat-dialog-close class="mainButton">Ok</button>
    </div>
</mat-dialog-content>