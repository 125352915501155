import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'error-dialog',
  standalone: true,
  imports: [MatDialogModule],
  templateUrl: './error-dialog.component.html',
  styleUrl: './error-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    this.errorMessage = data.errorMessage;
    this.stacktrace = data.stacktrace;
    this.eventId = data.eventId;
  }

  readonly errorMessage: string;
  readonly stacktrace?: string | null;
  readonly eventId?: string;

}
export interface DialogData {
  errorMessage: string,
  eventId?: string,
  stacktrace?: string | null
}